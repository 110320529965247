<template>
    <CtaSection
        v-editable="blok"
        :data="ctaSection"
        class="pb-16 pt-8 lg:pt-16"
    />
</template>

<script setup lang="ts">
import ButtonType from '~/types/ButtonType';
import CtaSectionType from '~/types/CtaSectionType';
import CtaSection from '~/components/page-building/CtaSection.vue';

const props = defineProps({
    blok: {
        type: Object,
        required: true,
    },
    nextBlock: {
        type: Object,
        required: false,
        default: null,
    },
});

const ctaSection = computed<CtaSectionType>(() => {
    return {
        title: props.blok.title,
        text: props.blok.text,
        sectionColor: props.blok.section_color,
        backgroundColor: props.blok.background_color
            ? props.blok.background_color
            : 'white',
        button: props.blok.button[0]
            ? ({
                  label: props.blok.button[0].label,
                  style: props.blok.button[0].style,
                  url: props.blok.button[0].link.cached_url,
                  vEditable: props.blok.button[0],
              } as ButtonType)
            : null,
    };
});
</script>
